.header {
  z-index: 2;
  font-family: 'uncut sans';
  font-weight: 400;
  font-optical-sizing: auto;
  color: #cccccc;
  text-transform: uppercase;
  font-size: 2rem;
  margin-block: 0;
  margin-top: 1rem;
  /* position: fixed; */
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
  }
  
  .nav-links {
    list-style: none;
    margin-block: inherit;
    display: flex;
  }
  
  .nav-links li {
  margin-right: 1rem;
  }
  
  .nav-links a {
    text-decoration: none;
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    margin-block: inherit;
  }
  
  h1.logo {
    margin: 0 0 0 1rem;
    font-weight: inherit;
    font-size: inherit;
    margin-block: inherit;
}
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

/* Full-screen section */
.hero-section {
  position: relative;
  height: auto;
  width: 100vw;
  text-align: center;
}

/* Background video */
.video-bg {
  width: 100vw;
  height: auto;
  z-index: 0;
  /* max-width: 83rem; */
}

/* Hero content */
.hero-content {
  font-family: 'lunchtype22light';
  font-size: 1.7rem;
  color: #cccccc;
  text-align: center;
  margin: 10vh 0;
}

.hero-content h1 {
  font-size: 790%;
  font-family: lunchtype24condlight;
  font-weight: 100;
  margin: 1rem 0;
  margin-block: 0;
  letter-spacing: -0.6rem;
  color: #333333;
}

.hero-content p {
  font-family: 'uncut sans';
  font-weight: 300;
  font-optical-sizing: auto;
  font-size: 272%;
  line-height: 1;
  text-transform: capitalize;
  margin-block: 0;
  letter-spacing: -0.2rem;
  word-spacing: 1rem;
}

/* CTA button */
.cta-button {
  font-size: 70%;
  color: #1d1d1d;
  background: #f1f1f1;
  text-decoration: none;
  font-family: 'Spline Sans Mono', monospace;
  border: 1px solid;
  border-radius: 5px;
  transition: background-color 0.1s ease;
  cursor: pointer;
  padding: 1rem 2rem;
  margin-top: 1.5rem;
  display: inline-block;
}

/* Keyframes for animating the button from center to left */
@keyframes animateIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Sticky class */
.sticky {
  position: fixed;
  bottom: 3rem; /* Final position on the left */
  left: 50%;
  transform: translateX(-50%); /* Ensure it's fully aligned to the left */
  z-index: 1000;
  box-shadow: black 0 0 2rem;
  animation: animateIn 0.5s ease-out forwards; /* Add the animation */
}

.cta-button:hover {
  background-color: blue;
  border-color: blue;
  color: white;
}

.ghost {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  visibility: hidden; /* Hide the ghost element, but still take up space */
}
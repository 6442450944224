/* Imports */
@import url('https://fonts.googleapis.com/css2?family=Spline+Sans+Mono:wght@400&display=swap');

@font-face {
  font-family: 'lunchtype22regular';
  src: url('../../public/fonts/lunchtype22/lunchtype22-regular-webfont.eot');
  src: url('../../public/fonts/lunchtype22/lunchtype22-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../public/fonts/lunchtype22/lunchtype22-regular-webfont.woff2') format('woff2'),
       url('../../public/fonts/lunchtype22/lunchtype22-regular-webfont.woff') format('woff'),
       url('../../public/fonts/lunchtype22/lunchtype22-regular-webfont.ttf') format('truetype'),
       url('../../public/fonts/lunchtype22/lunchtype22-regular-webfont.svg#lunchtype22regular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'lunchtype22medium';
  src: url('../../public/fonts/lunchtype22/lunchtype22-medium-webfont.eot');
  src: url('../../public/fonts/lunchtype22/lunchtype22-medium-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../public/fonts/lunchtype22/lunchtype22-medium-webfont.woff2') format('woff2'),
       url('../../public/fonts/lunchtype22/lunchtype22-medium-webfont.woff') format('woff'),
       url('../../public/fonts/lunchtype22/lunchtype22-medium-webfont.ttf') format('truetype'),
       url('../../public/fonts/lunchtype22/lunchtype22-medium-webfont.svg#lunchtype22medium') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'lunchtype22light';
  src: url('../../public/fonts/lunchtype22/lunchtype22-light-webfont.eot');
  src: url('../../public/fonts/lunchtype22/lunchtype22-light-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../public/fonts/lunchtype22/lunchtype22-light-webfont.woff2') format('woff2'),
       url('../../public/fonts/lunchtype22/lunchtype22-light-webfont.woff') format('woff'),
       url('../../public/fonts/lunchtype22/lunchtype22-light-webfont.ttf') format('truetype'),
       url('../../public/fonts/lunchtype22/lunchtype22-light-webfont.svg#lunchtype22light') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'lunchtype24expandedregular';
  src: url('../../public/fonts/lunchtype24/lunchtype24-regular-expanded-webfont.eot');
  src: url('../../public/fonts/lunchtype24/lunchtype24-regular-expanded-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../public/fonts/lunchtype24/lunchtype24-regular-expanded-webfont.woff2') format('woff2'),
       url('../../public/fonts/lunchtype24/lunchtype24-regular-expanded-webfont.woff') format('woff'),
       url('../../public/fonts/lunchtype24/lunchtype24-regular-expanded-webfont.ttf') format('truetype'),
       url('../../public/fonts/lunchtype24/lunchtype24-regular-expanded-webfont.svg#lunchtype24expandedregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'lunchtype24expandedmedium';
  src: url('../../public/fonts/lunchtype24/lunchtype24-medium-expanded-webfont.eot');
  src: url('../../public/fonts/lunchtype24/lunchtype24-medium-expanded-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../public/fonts/lunchtype24/lunchtype24-medium-expanded-webfont.woff2') format('woff2'),
       url('../../public/fonts/lunchtype24/lunchtype24-medium-expanded-webfont.woff') format('woff'),
       url('../../public/fonts/lunchtype24/lunchtype24-medium-expanded-webfont.ttf') format('truetype'),
       url('../../public/fonts/lunchtype24/lunchtype24-medium-expanded-webfont.svg#lunchtype24expandedmedium') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'lunchtype24expandedlight';
  src: url('../../public/fonts/lunchtype24/lunchtype24-light-expanded-webfont.eot');
  src: url('../../public/fonts/lunchtype24/lunchtype24-light-expanded-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../public/fonts/lunchtype24/lunchtype24-light-expanded-webfont.woff2') format('woff2'),
       url('../../public/fonts/lunchtype24/lunchtype24-light-expanded-webfont.woff') format('woff'),
       url('../../public/fonts/lunchtype24/lunchtype24-light-expanded-webfont.ttf') format('truetype'),
       url('../../public/fonts/lunchtype24/lunchtype24-light-expanded-webfont.svg#lunchtype24expandedlight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'lunchtype24condregular';
  src: url('../../public/fonts/lunchtype25/lunchtype25-regular_condensed-webfont.eot');
  src: url('../../public/fonts/lunchtype25/lunchtype25-regular_condensed-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../public/fonts/lunchtype25/lunchtype25-regular_condensed-webfont.woff2') format('woff2'),
       url('../../public/fonts/lunchtype25/lunchtype25-regular_condensed-webfont.woff') format('woff'),
       url('../../public/fonts/lunchtype25/lunchtype25-regular_condensed-webfont.ttf') format('truetype'),
       url('../../public/fonts/lunchtype25/lunchtype25-regular_condensed-webfont.svg#lunchtype24condregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'lunchtype24condmedium';
  src: url('../../public/fonts/lunchtype25/lunchtype25-medium-condensed-webfont.eot');
  src: url('../../public/fonts/lunchtype25/lunchtype25-medium-condensed-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../public/fonts/lunchtype25/lunchtype25-medium-condensed-webfont.woff2') format('woff2'),
       url('../../public/fonts/lunchtype25/lunchtype25-medium-condensed-webfont.woff') format('woff'),
       url('../../public/fonts/lunchtype25/lunchtype25-medium-condensed-webfont.ttf') format('truetype'),
       url('../../public/fonts/lunchtype25/lunchtype25-medium-condensed-webfont.svg#lunchtype24condmedium') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'lunchtype24condlight';
  src: url('../../public/fonts/lunchtype25/lunchtype25-light_condensed-webfont.eot');
  src: url('../../public/fonts/lunchtype25/lunchtype25-light_condensed-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../public/fonts/lunchtype25/lunchtype25-light_condensed-webfont.woff2') format('woff2'),
       url('../../public/fonts/lunchtype25/lunchtype25-light_condensed-webfont.woff') format('woff'),
       url('../../public/fonts/lunchtype25/lunchtype25-light_condensed-webfont.ttf') format('truetype'),
       url('../../public/fonts/lunchtype25/lunchtype25-light_condensed-webfont.svg#lunchtype24condlight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Uncut Sans';
  src: url('../../public/fonts/uncutsans/UncutSans-BdIt.woff2') format('woff2'),
      url('../../public/fonts/uncutsans/UncutSans-BdIt.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Uncut Sans';
  src: url('../../public/fonts/uncutsans/UncutSans-Bd.woff2') format('woff2'),
      url('../../public/fonts/uncutsans/UncutSans-Bd.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uncut Sans Book';
  src: url('../../public/fonts/uncutsans/UncutSans-Bk.woff2') format('woff2'),
      url('../../public/fonts/uncutsans/UncutSans-Bk.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uncut Sans';
  src: url('../../public/fonts/uncutsans/UncutSans-Lt.woff2') format('woff2'),
      url('../../public/fonts/uncutsans/UncutSans-Lt.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uncut Sans';
  src: url('../../public/fonts/uncutsans/UncutSans-LtIt.woff2') format('woff2'),
      url('../../public/fonts/uncutsans/UncutSans-LtIt.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Uncut Sans Book';
  src: url('../../public/fonts/uncutsans/UncutSans-BkIt.woff2') format('woff2'),
      url('../../public/fonts/uncutsans/UncutSans-BkIt.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Uncut Sans';
  src: url('../../public/fonts/uncutsans/UncutSans-Rg.woff2') format('woff2'),
      url('../../public/fonts/uncutsans/UncutSans-Rg.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uncut Sans';
  src: url('../../public/fonts/uncutsans/UncutSans-MdIt.woff2') format('woff2'),
      url('../../public/fonts/uncutsans/UncutSans-MdIt.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Uncut Sans';
  src: url('../../public/fonts/uncutsans/UncutSans-Md.woff2') format('woff2'),
      url('../../public/fonts/uncutsans/UncutSans-Md.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uncut Sans';
  src: url('../../public/fonts/uncutsans/UncutSans-RgIt.woff2') format('woff2'),
      url('../../public/fonts/uncutsans/UncutSans-RgIt.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Uncut Sans';
  src: url('../../public/fonts/uncutsans/UncutSans-SmBdIt.woff2') format('woff2'),
      url('../../public/fonts/uncutsans/UncutSans-SmBdIt.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Uncut Sans';
  src: url('../../public/fonts/uncutsans/UncutSans-SmBd.woff2') format('woff2'),
      url('../../public/fonts/uncutsans/UncutSans-SmBd.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


/* Variables */
:root {
--light-color: #c1c1c1;
--medium-light-color: #a4a4a4;
--dark-color: #000000cc;
--medium-dark-color: #0f0f0f;
}
/* Global */
html {
    font-size: 16px;
    background-color: var(--medium-dark-color);
}
footer {
  position: fixed; 
  bottom: 0;
  font-size: .7rem;
  right: 0;
}

/* Canvas */
.zoom-container {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.zoom-center {
}
/* UI */
.ui .toolbar {
  position: fixed;
  top: 10%;
  left: 50%;
  background: var(--medium-dark-color);
  /* width: 100%; */
  height: 30px;
  padding: 5px 5px 5px 10px;
  color: #d0c4c4;
  backdrop-filter: blur(100px);
  border-radius: 5px;
  margin-left: -390px;
  border: 2px solid rgba(255 255 255 / 0.07);
  filter: drop-shadow(0px 0px 12px #000000);
}
.toolbar-handle {
  position: relative;
  /* top: -1px; */
}
.toolbar-handle .handle {
    width: 20px;
    display: inline-block;
    word-wrap: break-word;
    line-height: 0.75;
    font-size: 10px;
    cursor: grab;
    padding-right: 5px;
}

.image-display {
  pointer-events: none;
}
/* Image Uploader */
.dropzone p {
  border: 2px dashed var(--light-color);
  color: var(--light-color);
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 36px;
  width: 200px;
}
.dropzone {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

/* Crop Layers */
.crop-layers {
  position: fixed;
  bottom: 5px;
  left: 5px;
  z-index: 9;
  font-size: .8rem;
  background: var(--medium-dark-color);
  padding: 0 0 0 0;
  color: var(--light-color);
  -webkit-backdrop-filter: blur(100px);
  backdrop-filter: blur(100px);
  border-radius: 5px;
  border: 2px solid rgba(255 255 255 / 0.07);
  overflow: hidden;
}

.crop-layers .toolbar-handle {
  position: absolute;
  right: 0px;
}
.crop-layer-name.selected {
  font-weight: bold;
  color: var(--dark-color);
  background-color: var(--medium-light-color);
}

.crop-layer-name, .crop-layer-label {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding: 5px 55px 5px 10px;
}

.crop-layer-label {
  background: var(--dark-color);
  color: var(--light-color);
}
.crop-layer-name {
  cursor: pointer;
}

crop-layer-label .toolbar-handle {
  right: 0;
  position: absolute;
}

.crop-layer-name:last-child {
  border-bottom: none;
}

span.remove-layer {
  position: absolute;
  right: 5px;
}
/* Crop Frame */
.crop-frame {
    border: solid #e1e1e17a;
    outline: #0000007a solid;
    display: block;
    position: absolute;
    transform: translateX(0) translateY(0);
    will-change: transform, width, height; /* Hint that transform and size will change */
}
.crop-frame:hover {
    border: solid blue;
}
.crop-frame.selected {
  border:  solid var(--light-color);
}
.frame-label {
    position: absolute;
    font-size: inherit;
    color: var(--light-color);
    background: var(--dark-color);
    padding: 2px;
}
.crop-frame.selected .frame-label{
    background: var(--light-color);
    color: var(--dark-color);
}
.crop-frame.grabbing {
  cursor: grabbing !important;
}
.crop-frame.grabbed {
  cursor: grab;
}
.crop-frame.resizing {
  cursor: nwse-resize !important; /* Use appropriate resize cursor for your design */
}
/* Crop Frame Resize Handles */
.resize-handle {
  position: absolute;
  /* width: 10px;
  height: 10px;
  background-color: #007bff; 
  border: 2px solid #fff;   
  border-radius: 50%; */
  width: 20px;
  height: 20px;
  background: rgba(0, 0, 255, 0.55);
  backdrop-filter: blur(12px);
  transform: translateX(0) translateY(0) scaleX(1) scaleY(1);
}
.resize-handle::after {
  content: ' ';
  display: block;
  border-style: solid;
  border-bottom: 1px;
  border-right: 1px;
  height: 5px;
  width: 5px;
  margin-top: -13px;
  margin-left: 3px;
  color: var(--light-color);
}
.resize-handle::before {
  content: ' ';
  display: block;
  border-style: solid;
  border-top: 1px;
  border-left: 1px;
  height: 5px;
  width: 5px;
  margin-top: 10px;
  margin-left: 10px;
  color: var(--light-color);
}

  
/* Individual Resize Handle Styles (e.g., northwest, northeast, etc.) */
.resize-handle.tl {
  top: -8px;
  left: -8px;
  cursor: nwse-resize; /* Diagonal resize cursor */
}

.resize-handle.tr {
  top: -8px;
  right: -8px;
  cursor: nesw-resize; /* Diagonal resize cursor */
}

.resize-handle.br {
  bottom: -8px;
  right: -8px;
  cursor: nwse-resize; /* Diagonal resize cursor */
}

.resize-handle.bl {
  bottom: -8px;
  left: -8px;
  cursor: nesw-resize !important; /* Diagonal resize cursor */
}
/* Hover effect for resize handles (optional) */
.resize-handle:hover {
  background-color: #0056b3; /* Change the background color on hover */
}
button.remove-button:after {
  content: '+';
  transform: rotate(45deg);
  display: block;
  margin: -2px 0 0 -4px;
}
button.remove-button {
  position: absolute;
  right: -9px;
  top: -13px;
  color: var(--light-color);
  font-size: 2rem;
  background: blue;
  width: 24px;
  text-align: center;
  height: 26px;
  line-height: 0;
}

/* Grid */
.grid {
  display: grid;
}

/* Download */
.downloads {
  position: fixed;
  width: calc(100vw - 20px);
  height: 75vh;
  bottom: 0;
  left: 0;
  background:rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  padding: 10px;
  z-index: 9;
}
.downloads::before {
  content: ' ';
  display: block;
  position: fixed;
  height: 25vh;
  top: -25vh;
  left: 0;
  width: 100%;
  background-color: var(--dark-color));
  backdrop-filter: blur(10px);
}
.downloads section {
  text-align: center;
  display: grid;
  justify-content: start;
  justify-items: start;
}
.download {
  display: block;
}
.download span {
  background: var(--dark-color);
  color: var(--light-color);
}
.download span:first-child {
  background: transparent;
  color: var(--dark-color);
}
.download span:last-child {
  margin-right: 0;
}

.download-tools {
  display: inline-block;
  border: 1px solid;
  margin: 0 0 10px;
  border-radius: 5px;
}
.download-tools span {
  padding: 10px;
  display: inline-block;
  border-right: 1px solid;
}
.download-tools span:last-child {
  border: none;
}
.grid.cropped-images {
  grid-template-columns: repeat(5, minmax(250px, 1fr));
  grid-auto-rows: 250px; 
  overflow: scroll;
  position: absolute;
  width: calc(100% - 20px);
  height: calc(75vh - 65px);
  gap: 25px;
}
.grid.cropped-images > div {
  display: grid;
  grid-template-rows: 1fr auto; /* 1fr for the image to center it, auto for the content */
  position: relative;
  overflow: hidden;
  align-items: center; /* Center content vertically within each grid row */
  border: 1px solid rgba(0,0,0,0.1); 
  margin: -0.5px;
  border-radius: 10px;
}
.grid.cropped-images > div:hover {
  border-color: blue;
}
.grid.cropped-images div img {
  max-width: 150px;
  max-height: 150px;
  object-fit: contain;
  margin: 0 auto;
  grid-row: 1 / 2; /* Place the image in the first row */
}

.grid.cropped-images div .break {
  grid-row: 2 / 3; /* Place the content in the second row */
  align-self: end; /* Align the content to the bottom of the second row */
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.break p {
  padding: 10px 10px 0;
}
.cropped-images #select {
  position: absolute;
  top: 10px;
  left: 10px;
}
button.close {
  position: absolute;
  right: 0;
}


body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Spline Sans Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-transform: uppercase; */
}
.click {
  cursor: pointer;
  text-decoration: underline;
}
code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;  */
}

form {
  display: inline-block;
}
button, input {
  border: 0px solid;
  padding: 6px 8px;
  margin: 1px 4px;
  font-family: inherit;
}
input[type="submit"] {
  cursor: pointer;
}
button {
  border-color: #ededed;
  background: transparent;
  color: #d5d5d5;
  cursor: pointer;
}
.downloads button {
  color: var(--dark-color);
}

.downloads button:hover {
  box-shadow: inset 0px 0px 0px 1px var(--dark-color);
}
button:hover {
  -webkit-box-shadow:inset 0px 0px 0px 1px #d5d5d5;
  -moz-box-shadow:inset 0px 0px 0px 1px #d5d5d5;
  box-shadow:inset 0px 0px 0px 1px #d5d5d5;
}
.crop-buttons {
  margin-left: 12px;
}
button img {
  max-width: 22px;
  margin: -5px;
}

input {
  border-color:  var(--dark-color);
  background: #ededed;
  color:  var(--dark-color);
}
input::placeholder {
  color: var(--dark-color); /* Change the placeholder color */
}
input:focus {
  outline: none;
}
input:focus::placeholder {
  color: rgb(149, 149, 149); /* Hide the placeholder text when input is focused */
}
::placeholder {
  color:  var(--dark-color);
}
.inline {
  display: inline;
}
.hide {
  display: none;
}

.icon {
  position: relative;
  width: 30px; /* Adjust width as needed */
  height: 30px; /* Adjust height as needed */
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}
.x {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px; /* Adjust thickness of the line */
  background-color: var(--dark-color); /* Adjust color as needed */
  transform-origin: center;
}

.x:first-child {
  transform: translate(-50%, -50%) rotate(45deg); /* Diagonal line 1 */
}

.x:last-child {
  transform: translate(-50%, -50%) rotate(-45deg); /* Diagonal line 2 */
}
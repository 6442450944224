footer {
  position: fixed; 
  bottom: 1rem;
  font-size: .7rem;
  width: 100vw;
  text-align: center;
  color: lightgray;
}
footer a {
  color: #ccc;
}